import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import Layout from '../components/layout';
import Editor from '../components/Editor';
import TitleInput from '../components/TitleInput';
import PublishModal from '../components/PublishModal';
import BottomBar from '../components/BottomBar';
import getCategories from '../services/getCategories';
import getTags from '../services/getTags';
import { useLocalStorage, useMediaQuery, useAsyncFn } from '../utils/hooks';
import articlesClient from '../services/ArticlesClient';

// NOTE: not clear why named import cannot be found
import reducers from '../utils/reducers';

const { articleDetailsReducer, defaultArticleDetails } = reducers;

const defaultTextValue = [
  {
    children: [
      {
        text: '',
      },
    ],
  },
];

const Write = (props) => {
  const {
    className,
    location,
  } = props;

  const article = location?.state?.article || defaultArticleDetails(undefined);

  // Styles
  const { breakpointS } = useTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpointS})`);

  // Local State
  const [articleDetails, articleDetailsDispatch] = useReducer(articleDetailsReducer, article);
  const [text, setTextToLocalStorage] = useLocalStorage(`text-${articleDetails.id}`, defaultTextValue);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);

  // TODO: Move tags + categories queries to buildtime
  // eslint-disable-next-line no-unused-vars, max-len
  const [{ isLoading: isLoadingCategories, isError: isErrorCategories, data: categoryOptions }] = useAsyncFn(getCategories);
  // eslint-disable-next-line no-unused-vars, max-len
  const [{ isLoading: isLoadingTags, isError: isErrorTags, data: tagOptions }] = useAsyncFn(getTags);

  const handlePublishModal = (event) => {
    event.preventDefault();
    setIsPublishModalOpen(true);
  };

  const handleSaveDraft = async (event) => {
    event.preventDefault();
    try {
      // TODO: parallelize
      await Promise.all(
        articlesClient.updateDraftArticle(articleDetails),
        articlesClient.updateArticleTextById(articleDetails.id, text),
      );
    } catch (err) {
      console.error('Failed to save', err);
    }
  };

  const handleOnChangeArticleDetail = (key) => (value) => {
    articleDetailsDispatch({ type: 'ARTICLE_UPDATE_KEY', key, value });
  };

  const bottomBarActions = [
    {
      color: 'secondary',
      variant: 'solid',
      size: 'small',
      children: 'Publish',
      onClick: handlePublishModal,
    },
    {
      color: 'primary',
      variant: 'outlined',
      size: 'small',
      children: 'Save Draft',
      onClick: handleSaveDraft,
    },
  ];

  return (
    <Layout>
      <div className={className}>
        <TitleInput
          title={articleDetails.title}
          onChangeTitle={handleOnChangeArticleDetail('title')}
        />
        <Editor
          initialValue={text}
          setText={setTextToLocalStorage}
        />
        <BottomBar
          actions={bottomBarActions}
        />
        <PublishModal
          isFullScreen={isSmallScreen}
          isModalOpen={isPublishModalOpen}
          setIsModalOpen={setIsPublishModalOpen}
          article={articleDetails}
          text={text}
          onChangeArticleDetail={handleOnChangeArticleDetail}
          categoryOptions={categoryOptions}
          tagOptions={tagOptions}
        />
      </div>
    </Layout>
  );
};

Write.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      article: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
  }).isRequired,
};

Write.defaultProps = {
  className: undefined,
};

export default Write;
