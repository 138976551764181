import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const Title = (props) => {
  const {
    title,
    onChangeTitle,
    className,
  } = props;

  const handleChange = (event) => {
    event.preventDefault();
    onChangeTitle(event.target.value);
  };

  return (
    <TextField
      id="title"
      className={className}
      placeholder="Title..."
      name="title"
      value={title}
      margin="normal"
      onChange={handleChange}
      fullWidth
      required
    />
  );
};

Title.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired, // required because input should always be controlled
  onChangeTitle: PropTypes.func.isRequired,
};

export default Title;
